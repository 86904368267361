/* General Styling for Cards Container */
.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  background-color: rgb(40, 44, 52);
}


/* Styling for Each Card */
.card {
  /* Dimensions */
  width: 300px;
  height: 400px;
  /* Borders and Shadows */
  border-radius: 12.8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); /* Soft white shadow */
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Background */
  background-size: 100% 100%; /* Show the full image */
  background-repeat: no-repeat; /* Prevent tiling */
  background-position: center; /* Center the image */
  /* Interactions */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  text-decoration: none; /* Remove underline for links */
  color: rgb(255, 255, 255); /* White text color */
  /* Overflow Handling */
  overflow: hidden;
}


.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px #faf9f9;
  background-size: auto;
  /* box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); */
}

/* Styling for Card Content */
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
  padding: 1%;
  z-index: 1; /* Ensure text is above the background image */
}

/* Title at the Top of the Card */
.card-title {
  margin: auto;
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(192, 192, 192, 1.5);
}

/* Subtitle at the Bottom of the Card */
.card-subtitle {
  margin-bottom: 10%;
  font-size: 1em;
  font-style: italic;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}


/* Responsive layout */
@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 80%;
  }
}
