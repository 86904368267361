/* Styling for the Contact Component */
.contact-container {
  display: flex;
  background-color: rgb(40, 44, 52);
  justify-content: center;
  align-items: center;
  gap: 15px; /* Space between icons */
  padding: 20px;
}

/* Contact Icons */
.contact-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 50%; /* Makes icons circular */
  object-fit: cover; /* Ensures the image fits within the circular area */
}

.contact-icon:hover {
  transform: scale(1.2);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
}
