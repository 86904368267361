.App {
  text-align: center;
  background-color: rgb(64, 0, 70);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .background {
}

.background-container {
}

.background-container::before {
}

.background-container::after {
} */

.App-header {
  background-color: rgb(0, 83, 250);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Styling for the main container */
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* Optional: Adjust width for layout */
  margin: 0 auto; /* Centers the container horizontally */
  border: 2px solid rgb(222 187 104); /* Blue border */
  border-radius: 10px; /* Optional: Rounded corners */
  padding: 20px; /* Spacing inside the container */
  box-sizing: border-box; /* Ensures padding does not affect width */
  background-color: rgb(255, 0, 149);
}

